import React from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from "features/layout"
import { Button } from "features/ui"
import navigation, { social, featuredSlate, contact } from "constants"
import logo from "assets/images/logoBoth.png"

const ProductsRoot = () => {
  return (
    <div className="bg-white">
      {/* <div className="grid md:grid-cols-3 grid-cols-2 p-3 lg:px-8">
        <div className="flex items-center justify-start">
          <img
            src={logo}
            alt="Fine Linen Creation Logo"
            className="h-auto max-h-20 w-auto"
          />
        </div>
        <div className="hidden md:flex items-center text-xl font-medium justify-center gap-x-6">
          Products
        </div>
        <div className="flex items-center justify-end gap-x-6">
          <Button
            type="link"
            className="hidden md:flex underline"
            linkProps={{ to: "/home/contact" }}
          >
            Contact us
          </Button>
          <Button type="link" linkProps={{ to: "/home" }} primary>
            Web Site
          </Button>
        </div>
      </div> */}
      <Header navigation={navigation} image={featuredSlate} />
      <div className="max-w-full">
        <Outlet />
      </div>
      <Footer
        navigation={navigation}
        social={social}
        image={featuredSlate}
        company={contact}
        logo={logo}
        hide
      />
    </div>
  )
}

export default ProductsRoot
