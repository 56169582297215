import { createBrowserRouter, RouterProvider } from "react-router-dom"
import {
  Root,
  Home,
  About,
  Services,
  Gallery,
  Contact,
  Error,
  Products,
  ProductsRoot,
} from "./pages"

const router = createBrowserRouter([
  {
    path: "/home",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
  {
    path: "/",
    element: <ProductsRoot />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Products />,
      },
    ],
  },
])

const Routes = () => {
  return <RouterProvider router={router} />
}

export default Routes
