import React, { useState } from "react"
import { Bars3Icon } from "@heroicons/react/24/outline"
import logo from "assets/images/logoBoth.png"
import logoFlc from "assets/images/logoFLC.png"
import { Button } from "features/ui"
import MobileHeader from "./mobile-header"

const Header = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { navigation, image } = props

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }
  const openMobileMenu = () => {
    setMobileMenuOpen(true)
  }

  return (
    <header>
      {props.hidden ? (
        <div className="w-full"></div>
      ) : (
        <div className="w-full">
          <img
            style={image ? {} : { display: "none" }}
            src={image}
            alt="Featured products with company logo overlay"
            className="w-full h-auto"
          />
        </div>
      )}
      <nav
        className="mx-auto border-b flex items-center justify-start py-4 px-10 lg:px-12"
        aria-label="Global"
      >
        <div className="flex flex-1 md:hidden py-4">
          <Button
            type="navlink"
            linkProps={{ to: "/home", className: "-m-1.5 p-1.5" }}
          >
            <span className="sr-only">Fine Linen Creation</span>
            <img
              className="h-20 flex-1 w-auto"
              src={logo}
              alt="Fine Linen Creation Formaly A Party Place"
            />
          </Button>
        </div>
        <div className="md:hidden flex">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center rounded-md p-2.5 text-gray-700"
            onClick={openMobileMenu}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden md:flex gap-x-12">
          {navigation?.map((item) => (
            <Button key={item.name} type="navlink" linkProps={{ to: item.to }}>
              {item.name}
            </Button>
          ))}
        </div>
        <div
          className="hidden md:flex md:flex-1 justify-end"
          onClick={closeMobileMenu}
        >
          <Button type="navlink" linkProps={{ to: "/" }}>
            Products
          </Button>
        </div>
      </nav>
      <MobileHeader
        onClose={closeMobileMenu}
        open={mobileMenuOpen}
        navigation={navigation}
        logo={logoFlc}
      />
    </header>
  )
}

export default Header
