import React from "react"
import { images } from "constants"

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ")
// }

const Gallery = () => {
  return (
    <main className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="py-24 text-center">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
          Gallery
        </h1>
        <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
          Thoughtfully designed events for any occasion
        </p>
      </div>

      <section
        aria-labelledby="filter-heading"
        className="border-t border-gray-200 pt-6"
      >
        <h2 id="gallery-heading" className="sr-only">
          Gallery
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mb-16">
          {images?.map(({ url, id }) => (
            <div key={id}>
              <img
                className="aspect-[1.618/1] object-cover object-center rounded-lg shadow-md"
                src={url}
                alt={`Fine Linen Creation - ${id}`}
              />
            </div>
          ))}
        </div>
      </section>
    </main>
  )
}

export default Gallery
