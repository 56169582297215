import React, { useEffect, useState } from "react";

const Products = () => {
  // eslint-disable-next-line
  const [dataForIframe, setDataForIframe] = useState(null);

  // Handle messages from the iframe
  const messageHandler = (e) => {
    if (e.origin !== 'https://a-party-place.pointofrentalcloud.com') {
      return;
    }

    if (e.data.event === 'data_for_iframe') {
      // Store the data received from the iframe
      setDataForIframe(e.data.data);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return (
    <div>
      <iframe
        title="Point of Rental"
        src="https://a-party-place.pointofrentalcloud.com/portal"
        id="por_content_iframe"
        width="1"
        style={{ minWidth: "100vw", minHeight: "100vh" }}
      ></iframe>
    </div>
  );
};

export default Products
