import React from "react"
import logoFlc from "assets/images/logoFLC.png"
import { TbTruckDelivery, TbBuildingWarehouse, TbFlower } from "react-icons/tb"
import { Button } from "features/ui"

const features = [
  {
    name: "Rentals.",
    description:
      "Provide us with the details of what you're looking for and we're certain you'll be happy with the end product.",
    icon: TbBuildingWarehouse,
  },
  {
    name: "Design.",
    description:
      "If you can imagine it, we can implement it. Have a talk with us and we'll ensure that nothing is missed.",
    icon: TbFlower,
  },
  {
    name: "Delivery.",
    description: "Hassle free set-up, delivery and take down service.",
    icon: TbTruckDelivery,
  },
]

const Services = () => {
  return (
    <>
      <div className="mx-auto max-w-7xl pt-24 sm:px-2 lg:px-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-20 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                  className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
                  src="https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/favorite_-_40_wtbesy"
                  alt=""
                />
                <div className="absolute inset-0 bg-gray-900 mix-blend-multiply" />
                <div
                  className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
                  aria-hidden="true"
                >
                  <div
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#eeb4ef] to-[#3e3e3e] opacity-40"
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                  />
                </div>
                <figure className="relative isolate">
                  <img src={logoFlc} alt="" className="h-20 w-auto" />
                  <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                    <p>
                      “I have been in the event industry for over 14 years. Over
                      those years, I have learned to tune my creativity to help
                      others bring their dream event into reality. With a team
                      of dedicated employees behind me, I can turn your big day
                      into the event of the year.”
                    </p>
                  </blockquote>
                  <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                    <strong className="font-semibold text-white">
                      Terry Dudley
                    </strong>{" "}
                    - Fine Linen Creation, Owner
                  </figcaption>
                </figure>
              </div>
            </div>
            <div>
              <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                {/* <p className="text-base font-semibold leading-7 text-indigo-600">
                  Services
                </p> */}
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Here to provide anything you need for your next big event.
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6">
                    Fine Linen Creation has a warehouse full of any event
                    related equipment. Weddings, birthdays, galas, conferences
                    or any other gathering event, we're sure to have the right
                    equipment for you.
                  </p>
                  <p className="mt-8">
                    Have a look at our world class selection of linen, perfect
                    for any theme. We strive to bring your vision into reality
                    for every single occasion, whenever you need it.
                  </p>
                  <p className="mt-8">
                    Everything we offer can be delivered and set up. We cater to
                    you, for you; no detail needs to be left unchecked.
                  </p>
                </div>
              </div>

              <div className="mt-10 flex items-center justify-start gap-x-6 border-t border-gray-900/10 pt-10">
                <Button primary type="link" linkProps={{ to: "/home/gallery" }}>
                  Gallery
                </Button>
                <Button type="link" linkProps={{ to: "/home/contact" }}>
                  Contact us <span aria-hidden="true">&rarr;</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden py-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  Services
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  A perfect day
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  With our services, we can transform any space into the perfect
                  setting for you, your friends, peers or loved ones.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>

                <div className="mt-10 flex items-center justify-center gap-x-6 border-t border-gray-900/10 pt-10">
                  <Button primary type="link" linkProps={{ to: "/" }}>
                    Our products <span aria-hidden="true">&rarr;</span>
                  </Button>
                </div>
              </div>
            </div>
            <img
              src="https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/favorite_-_82_g8qkzt"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Services
