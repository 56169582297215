import React from "react"
import { Button } from "features/ui"

const About = () => {
  return (
    <div className="overflow-hidden py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              About us
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              We specialize in providing top-tier party rentals tailored for
              grand events such as weddings, conferences and much more.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              At Fine Linen Creation, we understand the importance of creating
              memorable moments, and our dedicated team is committed to turning
              your vision into a stunning reality. Let us be a part of your
              event journey, ensuring it's an occasion to remember for a
              lifetime.
            </p>
            <div className="mt-10 flex">
              <Button type="link" primary linkProps={{ to: "/home/services" }}>
                Our services <span aria-hidden="true">&rarr;</span>
              </Button>
            </div>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/favorite_-_87_qt190k"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/favorite_-_11_rzzikd"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/favorite_-_35_jk0krz"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/favorite_-_52_e1fehp"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
