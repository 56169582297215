import React from "react"
import { useOutletContext, Link } from "react-router-dom"
import { eventCollection } from "constants"
import { Carousel } from "features/ui"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
// ADD HOME, api for photos and preload - then update css modules.
const Home = () => {
  const [currentIndex, setCurrentIndex, imagesCarousel] = useOutletContext()

  return (
    <main>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 mb-24">
        <div className="py-24 text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Photo book
          </h1>
          <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
            Click galleries to view
          </p>
        </div>

        <section className="border-t border-gray-200 pt-6">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
            <Link
              to="/gallery"
              className="flex items-center justify-center border bg-lavender-300/20 border-lavender-500 rounded-lg hover:scale-105 hover:text-lavender-600 hover:bg-lavender-300/30 hover:border-lavender-600 duration-300"
            >
              View All <span aria-hidden="true">→</span>
            </Link>
            {imagesCarousel?.map((item, key) => (
              <div
                key={key}
                onClick={() => setCurrentIndex(key)}
                className="isolate relative hover:scale-105 duration-300"
              >
                <img
                  className={`aspect-[1/1.3] object-cover object-center rounded-lg shadow-md ${
                    currentIndex === key && "scale-105"
                  }`}
                  src={item.src}
                  alt={`Carousel featured product ${key + 1}`}
                />
                <div className="absolute inset-0 flex items-end justify-center py-4 px-6 lg:px-8 group">
                  <div className="flex items-center justify-center">
                    <Link
                      to={item.to}
                      className={classNames(
                        key !== currentIndex
                          ? "hidden"
                          : "group-hover:flex text-sm lg:text-md border text-lavender-600 px-3.5 py-2 bg-lavender-300 border-lavender-500 rounded-md hover:scale-105 hover:bg-lavender-600 hover:border-lavender-600 duration-300 hover:text-white"
                      )}
                    >
                      {item.title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section aria-labelledby="featured-images" className="mt-16">
          <Carousel
            images={imagesCarousel}
            height="32rem"
            width="100%"
            iconSize={40}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </section>

        <section
          aria-labelledby="featured-heading"
          className="relative mt-16 overflow-hidden rounded-lg lg:h-96"
        >
          <div className="absolute inset-0">
            <img
              src={eventCollection}
              alt=""
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
          <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
          <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
            <div>
              <h2
                id="featured-heading"
                className="text-xl font-bold text-white"
              >
                Event Collection
              </h2>
              <p className="mt-1 text-sm text-gray-300">
                Imagine your next event by looking at our gallery, Instagram or
                Pinterest pages.
              </p>
            </div>
            <Link
              to="/home/gallery"
              className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
            >
              View the collection
            </Link>
          </div>
        </section>
      </div>
    </main>
  )
}

export default Home
