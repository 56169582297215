import React from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { Link } from "react-router-dom"

const Banner = ({ onClose, image }) => {
  return (
    <div className="flex items-center gap-x-6 bg-lavender-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <Link to="/home/about">
          <strong className="font-semibold">This Is Our Updated Site!</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} fill="white" />
          </svg>
          Still A Party Place, just a new look
        </Link>
      </p>
      <div className="flex flex-1 justify-end">
        <button
          onClick={onClose}
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

export default Banner
