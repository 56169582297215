import React, { useState } from "react"
import { Outlet } from "react-router-dom"
import { Header, Footer } from "features/layout"
import { Banner, Carousel } from "features/ui"
import navigation, {
  social,
  imagesCarousel,
  contact,
  featuredSlate,
} from "constants"
import Splash from "assets/decoration"
import logo from "assets/images/logoFLC.png"

//notes: move products to different route
// add cloudinary api calls
//
const Root = () => {
  const [showBanner, setShowBanner] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)

  const closeBanner = () => {
    setShowBanner(false)
  }

  return (
    <>
      {showBanner && <Banner onClose={closeBanner} />}
      <Header navigation={navigation} image={featuredSlate} />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <Outlet context={[currentIndex, setCurrentIndex, imagesCarousel]} />
      </div>
      <Footer
        navigation={navigation}
        social={social}
        company={contact}
        logo={logo}
      />
    </>
  )
}

export default Root
