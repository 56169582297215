import React from "react"
import { NavLink, Link } from "react-router-dom"
import styles from "./button.module.css"

const buttonTypes = {
  BUTTON: "button",
  LINK: "link",
  NAVLINK: "navlink",
  NAVLINKMOBILE: "navlinkmobile",
  ICON: "icon",
}

const Button = (props) => {
  const { type, children, linkProps, buttonProps, className, primary } = props

  switch (type) {
    case buttonTypes.LINK:
      return (
        <Link
          {...linkProps}
          className={`${
            primary ? styles.primary : styles.default
          } ${className}`}
        >
          {children}
        </Link>
      )
    case buttonTypes.ICON:
      return (
        <Link {...linkProps} className={`${styles.icon} ${className}`}>
          {children}
        </Link>
      )
    case buttonTypes.NAVLINK:
      return (
        <NavLink
          end
          {...linkProps}
          className={({ isActive }) =>
            isActive ? `${styles.default} ${styles.active}` : styles.default
          }
        >
          {children}
        </NavLink>
      )
    case buttonTypes.NAVLINKMOBILE:
      return (
        <NavLink
          {...linkProps}
          end
          className={({ isActive }) =>
            isActive ? `${styles.navmobile} ${styles.active}` : styles.navmobile
          }
        >
          {children}
        </NavLink>
      )
    default:
      return (
        <button
          {...buttonProps}
          className={`${
            primary ? styles.primary : styles.default
          } ${className}`}
        >
          {children}
        </button>
      )
  }
}

export default Button
