import React from "react"
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs"
import { RxDotFilled, RxDot } from "react-icons/rx"

//need error catch for props
const Carousel = (props) => {
  const { images, height, width, iconSize, currentIndex, setCurrentIndex } = props

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const goToSlide = (imageIndex) => {
    setCurrentIndex(imageIndex)
  }

  return (
    <div className="isolate relative">
      <div className="flex" style={{width: width ? width : '100%', height: height ? height : '100%'}}>
        {/* <img
          className="hidden md:flex h-auto min-h-full max-h-full w-1/2 lg:w-1/3 object-cover object-center rounded-l-lg"
          src={images[currentIndex + 1]?.src || images[currentIndex - 1]?.src}
          alt={`Featured showcase - ${
            currentIndex + 1 || currentIndex - 1
          } for Fine Linen Creation`}
          style={{ userSelect: "none" }}
          loading="lazy"
        /> */}
        <img
          className="flex-1 h-auto min-h-full max-h-full w-full object-cover object-center md:w-1/2 lg:w-1/3 rounded-lg"
          src={images[currentIndex]?.src}
          alt={`Featured showcase - ${currentIndex} for Fine Linen Creation`}
          style={{ userSelect: "none" }}
          loading="lazy"
        />
        {/* <img
          className="hidden lg:flex h-auto min-h-full max-h-full w-1/3 object-cover object-center rounded-r-lg"
          src={images[currentIndex + 2]?.src || images[currentIndex - 2]?.src}
          alt={`Featured showcase - ${
            currentIndex + 2 || currentIndex - 2
          } for Fine Linen Creation`}
          style={{ userSelect: "none" }}
          loading="lazy"
        /> */}
      </div>
      <div className="absolute inset-0 flex items-center justify-between py-4 px-6 lg:px-8 group">
        <div className="hidden group-hover:flex cursor-pointer rounded-full p-2 bg-black/20 text-white">
          <BsChevronCompactLeft onClick={prevSlide} size={iconSize ? iconSize : 30} />
        </div>
        <div className="hidden cursor-pointer bg-black/20 rounded-full text-white">
          {images.map((_, imageIndex) => (
            <div
              key={imageIndex}
              onClick={() => goToSlide(imageIndex)}
              className="text-2xl cursor-pointer"
            >
              {currentIndex === imageIndex ? <RxDotFilled /> : <RxDot />}
            </div>
          ))}
        </div>
        <div className="hidden group-hover:flex cursor-pointer rounded-full p-2 bg-black/20 text-white">
          <BsChevronCompactRight onClick={nextSlide} size={iconSize ? iconSize : 30} />
        </div>
      </div>
    </div>
  )
}

export default Carousel
