import React from "react"
import { Dialog } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { Button } from "features/ui"

const MobileHeader = (props) => {
  const { onClose, open, navigation, logo } = props

  return (
    <Dialog as="div" className="lg:hidden" open={open} onClose={onClose}>
      <div className="fixed inset-0 z-10" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm ring-1 ring-gray-900/10">
        <div className="flex items-center justify-between">
          <div className="-m-1.5 p-1.5">
            <span className="sr-only">Fine Linen Creation</span>
            <img className="h-14 w-auto" src={logo} alt="company logo" />
          </div>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={onClose}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6" onClick={onClose}>
              {navigation?.map((item) => (
                <Button
                  key={item.name}
                  type="navlinkmobile"
                  linkProps={{ to: item.to }}
                >
                  {item.name}
                </Button>
              ))}
            </div>
            <div className="py-6" onClick={onClose}>
              <Button type="navlinkmobile" linkProps={{ to: "/" }}>
                Products
              </Button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}

export default MobileHeader
