import React from "react"
import { Button } from "features/ui"
import { Link } from "react-router-dom"
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline"

//future: add image as an optional prop for above footer
const Footer = (props) => {
  const { navigation, social, image, company, logo, hide } = props

  return (
    <footer className="w-full border-t border-gray-200 pt-6 pb-2">
      <div className={hide || !image ? "hidden" : "isolate relative"}>
        <img
          style={image ? {} : { display: "none" }}
          src={image}
          alt="Featured products with company logo overlay"
          className="w-full h-auto"
        />
        <div className="absolute inset-0 flex items-end lg:items-center justify-center py-4 px-6 lg:px-8">
          <div className="flex items-center justify-center">
            <Link
              to="/"
              className="text-sm lg:text-lg border text-lavender-600 font-semibold px-3.5 py-2 bg-lavender-300/20 border-lavender-500 rounded-md hover:scale-105 hover:bg-lavender-600 hover:border-lavender-600 duration-300 hover:text-white"
            >
              See our inventory <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-around mx-auto px-6 py-4 lg:px-8 lg:pt-8 leading-5 text-gray-400">
        <div className="flex lg:flex-col lg:space-x-0 lg:space-y-5 space-x-10 justify-center p-4 ml-4">
          <dl className="leading-5 text-gray-600 text-sm">
            <div className="flex gap-x-4 pb-1">
              <dt className="flex-none">
                <span className="sr-only">Address</span>
                <BuildingOffice2Icon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                {company.line}
                <br />
                {`${company.city}, ${company.state.value}, ${company.zip}`}
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Telephone</span>
                <PhoneIcon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <a
                  className="hover:text-gray-900"
                  href={`tel:${company.phone}`}
                >
                  {company.phone}
                </a>
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Telephone</span>
                <PhoneIcon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <a
                  className="hover:text-gray-900"
                  href={`tel:${company.phone2}`}
                >
                  {company.phone2}
                </a>
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Email</span>
                <EnvelopeIcon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <a
                  className="hover:text-gray-900"
                  href={`mailto:${company.email}`}
                >
                  {company.email}
                </a>
              </dd>
            </div>
          </dl>
        </div>
        <div className="fex-col">
          <nav
            className="flex space-x-5 md:space-x-10 justify-center py-4 px-6"
            aria-label="Footer"
          >
            {navigation?.map((item) => (
              <div key={item.name}>
                <Button
                  type="icon"
                  className="text-sm leading-6"
                  linkProps={{ to: item.to }}
                >
                  {item.name}
                </Button>
              </div>
            ))}
          </nav>
          <div className="flex justify-center space-x-10 py-4">
            {social?.map((item) => (
              <Button type="icon" key={item.name} linkProps={{ to: item.href }}>
                {item.icon}
              </Button>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <img
            className="hidden lg:flex h-24 w-auto"
            src={logo}
            alt="Fine Linen Creation Formaly A Party Place"
          />
        </div>
      </div>
      <p className="text-center text-xs leading-5 text-gray-500">
        &copy; 2023 {company?.company || "[Company Name]"}. All rights reserved.
      </p>
    </footer>
  )
}

export default Footer
