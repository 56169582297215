import { FaYelp, FaPinterest, FaFacebook, FaInstagram } from "react-icons/fa"

export const imagesCarousel = [
  {
    src: "https://res.cloudinary.com/davewur6k/image/upload/v1696406772/fine-linen-creation/favorite_-_30_qsntd9.png",
    title: "Dance Floors",
    to: "/home/gallery",
  },
  {
    src: "https://res.cloudinary.com/davewur6k/image/upload/v1698565412/fine-linen-creation/featured-table_m67bdj.jpg",
    title: "Tabletop/Linens",
    to: "/home/gallery",
  },
  {
    src: "https://res.cloudinary.com/davewur6k/image/upload/v1698565414/fine-linen-creation/featured-tent-dark_tv8zks.jpg",
    title: "Lights",
    to: "/home/gallery",
  },
  {
    src: "https://res.cloudinary.com/davewur6k/image/upload/v1696406798/fine-linen-creation/favorite_-_82_g8qkzt.png",
    title: "Outdoors",
    to: "/home/gallery",
  },
]
export const featuredSlate =
  "https://res.cloudinary.com/davewur6k/image/upload/v1698798590/fine-linen-creation/bannerBottom_nxcbzx.jpg"
export const eventCollection =
  "https://res.cloudinary.com/davewur6k/image/upload/v1698565385/fine-linen-creation/featured-front_fq4bxd.jpg"

// FUTURE - api dataset for most of these constants
const baseUrl =
  "https://res.cloudinary.com/davewur6k/image/upload/f_auto,q_auto/v1/fine-linen-creation/"

const endUrls = [
  "hejasratmftfbho7qqhy",
  "fj3tau70kwaomfjiqa0z",
  "su66gyn0lsf1gyoalzcd",
  "favorite_-_92_uxacxh",
  "favorite_-_91_mzigy6",
  "favorite_-_102_zpzsjm",
  "favorite_-_93_zbn8st",
  "favorite_-_90_fdxjqo",
  "favorite_-_84_jnxj1h",
  "favorite_-_89_siet7g",
  "favorite_-_82_g8qkzt",
  "favorite_-_88_zy3f79",
  "favorite_-_87_qt190k",
  "favorite_-_85_de4alt",
  "favorite_-_76_klfixj",
  "favorite_-_52_e1fehp",
  "favorite_-_54_qcl5e5",
  "favorite_-_51_cupl9s",
  "favorite_-_53_zcbohm",
  "favorite_-_50_iahopn",
  "koxmlyj6hlogmttvsqiw",
  "favorite_-_46_fwuiwz",
  "favorite_-_48_ose3pk",
  "favorite_-_49_uyadxi",
  "favorite_-_42_azf4e2",
  "favorite_-_47_qke3t1",
  "favorite_-_5_xiduxx",
  "favorite_-_44_qvokxx",
  "favorite_-_45_qm76ar",
  "favorite_-_43_j2ajbt",
  "favorite_-_39_gs8iok",
  "aq0kyml8yvxmjcsui5ni",
  "favorite_-_40_wtbesy",
  "favorite_-_37_xjjm91",
  "favorite_-_36_wemno4",
  "favorite_-_32_cpcmel",
  "favorite_-_30_qsntd9",
  "favorite_-_9_ompk1v",
  "favorite_-_35_jk0krz",
  "favorite_-_33_qgtzxk",
  "favorite_-_31_jmvcxm",
  "favorite_-_27_qmpkkm",
  "favorite_-_28_zehwy7",
  "favorite_-_16_j3bgxf",
  "favorite_-_10_luzkm8",
  "favorite_-_13_cuwpsx",
  "favorite_-_15_ej2g1p",
  "favorite_-_14_jfvavd",
  "favorite_-_7_w3ippl",
  "favorite_-_12_s5xddg",
  "favorite_-_6_jajlke",
  "favorite_-_11_rzzikd",
  "favorite_-_29_qainkj",
]

export const navigation = [
  { name: "Home", to: "/home" },
  { name: "About", to: "/home/about" },
  { name: "Services", to: "/home/services" },
  { name: "Gallery", to: "/home/gallery" },
  { name: "Contact", to: "/home/contact" },
]

export const social = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/finelinencreation/",
    icon: <FaFacebook size="28px" />,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/finelinencreation/",
    icon: <FaInstagram size="28px" />,
  },
  {
    name: "Pinterest",
    href: "https://www.pinterest.com/finelinencreate/?eq=fine%20linen%20creation&etslf=8738",
    icon: <FaPinterest size="28px" />,
  },
  {
    name: "Yelp",
    href: "https://www.yelp.com/biz/fine-linen-creation-campbell?osq=Fine+Linen+Creation",
    icon: <FaYelp size="28px" />,
  },
]

export const contact = {
  company: "Fine Linen Creation",
  line: "1077 Dell Ave. Unit B",
  city: "Campbell",
  state: { name: "California", value: "CA" },
  zip: "95008",
  phone: "+1 (408) 216-9512",
  phone2: "+1 (408) 871 0752",
  email: "info@finelinencreation.com",
}

export const generateImageUrls = () => {
  return endUrls.map((end, index) => ({
    id: index,
    url: `${baseUrl}/${end}`,
  }))
}

export const images = generateImageUrls()

export default navigation
